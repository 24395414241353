.App{
  width:100%;
  height:100%;
  position: absolute;
  background-color: #333333;
}

html, body{
  background-color: #333333;
}

img.vcard-hd-image{
  position: absolute;
  object-fit: cover;
  width:100%;
  height:100%;
  transition: opacity 0.3s;
  opacity:0;
}



.notPWA img.vcard-hd-image{

  border-radius: 30px;
  left:20px;
  top:20px;
  width:calc(100% - 40px) !important;
  height:calc(100% - 120px) !important;
}


/* 
.notPWA img.vcard-hd-image.appleOpen{

  border-radius: 30px;
  left:20px;
  top:20px;
  width:calc(100% - 40px) !important;
  height:calc(100% - 210px) !important;
} */



.installbutton_wrapper{display:none}

.notPWA .installbutton_wrapper{
  display: block;
padding:20px;
width:calc(100% - 40px) !important;

position: absolute;
bottom:0px;
}

.installbutton{
width:100%;
height: 60px;
background-color: white;
opacity: 1;
color:#222;
text-align: center;
font-weight: bold;
font-size: 18px;
border-radius: 30px;
box-shadow: none;
border: 0px solid white;
cursor: pointer;
display: flex;
gap:0px;
align-items: flex-start;
justify-content: center;
transition: all 500ms;
padding-top:0px;
overflow: hidden;
flex-direction: column;
}


.installbutton_text{

  display: flex;
  width: 100%;
  gap:10px;
align-items: center;
justify-content: center;

}

.installbutton.appleOpen{
  border-radius: 30px;

height: 250px;}



.instructions{
  padding-top:0px;
  display: flex; width:100%;
  font-weight: normal !important;
  align-items: flex-start;
  justify-content: center;
  opacity: 0;
  height:0px;
  overflow: hidden;
  transition: opacity 1s;

}


.installbutton.appleOpen .instructions{
  padding-top:15px;

 display: flex; width:100%;
 font-weight: normal !important;
 align-items: flex-start;
 justify-content: center;
 opacity: 1;
 height:auto;
 overflow: hidden;

}


.iconinstall{

  min-width: 18px;
  min-height: 18px;
}
.installbutton svg{
max-width: 18px;
display: block;
}
.installbutton:hover{

  opacity: 1;


}


div.vcard-hd-cover{
  position: absolute;
  width:100%;
  height:100%;
  pointer-events: none;
  opacity:0;
  background-color: black;
  transition: opacity 0.5s;

}


div.vcard-hd-cover.appleOpen{
  pointer-events: all;

 opacity:0.75;
}




@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}


.debugInfo{
display: flex;
align-items: center;
  position: absolute;
  width:auto;
  left:20px;
  top:20px;
  background:black;
  color:white;
  font-size: 18px !important;
  padding:10px;
  max-width: calc(100% - 60px) !important;
  overflow: hidden;
  border-radius: 5px;
}

.desktopWrapper{

  width: 100%;
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: #333333;
  color:white;
  text-align: center;
}

.desktopWrapper div {
  padding-left:20px;
  padding-right:20px;
}


.desktopWrapper svg{
  margin-bottom: 10px;
  display: block;

  
}

.moveright{

  margin-right: 10px;;
}

.spinern{

animation-name: spin;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}

#pwa-install-element .material-button{
  background-color: #222 !important;
}